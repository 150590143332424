<template>
  <div class="deal-card is-relative">
    <slot name="component"></slot>
    <div class="p-2">
      <img :src="selectedDeal.image" class="image deal-card__img" alt="test image" v-if="selectedDeal.image" />
      <div class="deal-card__content">
        <slot></slot>
      </div>
    </div>
    <b-sidebar
      type="is-light"
      :fullheight="true"
      :fullwidth="false"
      :overlay="true"
      :right="true"
      v-model="open"
      class="edit-sidebar"
    >
      <div class="card">
        <header class="card-header">
          <h1 class="card-header-title">{{ settings.component }} bearbeiten</h1>
        </header>
        <div class="card-content p-6">
          <section class="content">
            <b-field label="Deal">
              <b-select v-model="selectedDeal">
                <option v-for="option in deals" :value="option" :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </section>
        </div>
        <footer class="card-footer is-flex is-align-items-center p-6">
          <b-button class="ml-auto" type="is-primary" icon-right="content-save" @click="saveHandler"
            >Speichern
          </b-button>
        </footer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      selectedDeal: {},
      deals: [
        {
          id: 1,
          name: 'Nike Air Max',
          image: require('@/assets/logo.png'),
        },
        {
          id: 2,
          name: 'Adidas Performance',
          image: require('@/assets/background.jpg'),
        },
      ],
    };
  },
  methods: {
    openHandler() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
.deal-card {
  border-radius: 8px;

  &__img {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
  }

  &__content {
    position: absolute;
    bottom: -20px;
    width: 80%;
    border-radius: 20px;
  }
}
</style>
